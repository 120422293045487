<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-form style="padding: 20px 20px 0" :model="queryForm" ref="queryForm" inline>
        <el-form-item label="艺术家昵称">
          <el-input
            v-model="queryForm.artist_name"
            placeholder="请输入艺术家昵称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="UCID">
          <el-input
            v-model="queryForm.artist_ucid"
            placeholder="请输入UCID"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动类型">
          <el-select v-model="queryForm.promotion_type">
            <el-option label="邀新礼" value="invite"> </el-option>
            <el-option label="新户礼" value="new_user"> </el-option>
            <el-option label="消费礼" value="purchase"> </el-option>
            <el-option label="回归礼" value="regress_user"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动状态">
          <el-select v-model="queryForm.status">
            <el-option label="进行中" :value="1"> </el-option>
            <el-option label="未开始" :value="2"> </el-option>
            <el-option label="已删除" :value="3"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleClickSearch">查询</el-button>
          <el-button type="info" @click="handleClickReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list" border stripe row-key="user_id">
      <el-table-column label="艺术家" prop="artist_name"> </el-table-column>
      <el-table-column label="UCID" prop="artist_ucid"> </el-table-column>
      <el-table-column label="活动类型" prop="promotion_type"> </el-table-column>
      <el-table-column label="单次数量" prop="limit_num"> </el-table-column>
      <el-table-column label="福袋名称" prop="lucky_bag_name"> </el-table-column>
      <el-table-column label="活动发放上限" prop="total_num"> </el-table-column>

      <el-table-column label="活动时间" prop="start_at_sec">
        <template slot-scope="scope">
          <div>
            {{ scope.row.start_at_sec | formatDateAtDay }} -
            {{ scope.row.end_at_sec | formatDateAtDay }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="已发数量" prop="send_num"> </el-table-column>
      <el-table-column label="活动状态" prop="status_desc"> </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getPromotionStatisticList } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        // promotion_type: "",
        // status: "",
      },
      list: [],
    };
  },
  filters: {
    formatDateAtDay: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          params[key] = this.queryForm[key];
        }
      });
      return params;
    },
    async fetchData() {
      try {
        const params = {
          ...this.getPatamsFromQueryForm(),
          page: this.page,
          page_size: this.pageSize,
        };
        const { data } = await getPromotionStatisticList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {}
    },
    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
    handleClickReset() {
      this.resetQuery();
      this.fetchData();
    },
    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.queryForm = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
  },
};
</script>
